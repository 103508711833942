export const DEFAULT_TIMEZONE_OFFSET_FOR_FIRST_BUILDING = -5;

export const MAX_ALLOWED_PROCESSING_FEE_IN_PROC = 10;

export const TOKEN_CHANGE_ERROR_MESSAGE_DELAY_IN_MS = 1000;

export const BUILDING_DETAILS_RELOAD_INTERVAL_IN_MS = 10 * 60 * 1000;
export const INVOICE_INDICATORS_UPDATE_PERIOD = 120000;

export const SERIAL_NUMBER_LENGTH = 5;
export const MAX_ADDRESS_LENGTH = 17;

export const MAX_LOCK_NAME_LENGTH = 16;
export const MAX_UNIT_NAME_LENGTH = 16;
export const MAX_UNIT_PREFIX_NAME_LENGTH = 10;

export const MAX_PAYMENT_DESCRIPTION_LENGTH = 100;

export const TEXT_FIELD_MAX_LENGTH = 250;
export const USERNAME_MAX_LENGTH = 250;
export const PHONE_MAX_LENGTH = 100;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 12;

export const MOBILE_MENU_CLOSE_DELAY_IN_MS = 200;

/** @type {{ [key: string]: import("types/commonTypes").LockType }} */
export const lockTypes = {
  MasterLock: "MasterLock",
  Yale: "Yale",
  OpenPath: "OpenPath",
}

export const steps = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
};

export const localStorageNameEnum = {
  LANG: "LANG",
  AUTH_TOKEN: "AUTH_TOKEN",
  AUTH_REFRESH_TOKEN: "AUTH_REFRESH_TOKEN",
  COOKIES_ARE_CONFIRMED: "COOKIES_ARE_CONFIRMED",
  STRIPE_ACCOUNT_CREATION_SKIPPED: "STRIPE_ACCOUNT_CREATION_SKIPPED",
  STRIPE_ACCOUNT_CREATION_STATE: "STRIPE_ACCOUNT_CREATION_STARTED",
};

export const paymentStatusLabel = {
  COMPLETED: undefined,
  PROCESSING: "Processing",
  PENDING: "Pending",
  FAILED: "Failed",
};
